import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddressForm from "./AddressForm";
import "../App.css";
import { checkUser } from "../services/firestore";
import { UserContext } from "../contexts/user";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://codingx.app/">
        CodingX
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function getStepContent(step, email) {
  switch (step) {
    case 0:
      return <AddressForm />;
    case 1:
      return <p>We are so sad to see you leave! 😞. Your email id {email} has been successfully unsubscribed from our mailing list. If you wish to join back, just re-signin using your email on our app.</p>;
    default:
      throw new Error("Unknown step");
  }
}

const theme = createTheme({
  palette: {
    background: {
      default: "#222222"
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    }
  }
});

export function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [state] = React.useContext(UserContext);

  const url = window.location.href;
  const email = url.split("/")[3] || "";

  React.useEffect(() => {
    if (email) {
      setActiveStep(1);
      checkUser(email).catch(e => toast('Failed to unsubscribe. Try again later.'));
      toast('Successfully unsubscribed from mailing list.', { autoClose: 5000 });
    }
  }, []);

  const handleNext = async () => {
    const res = await checkUser(state.email).catch(e => toast('Failed to unsubscribe. Try again later.'));
    if (res.res.status === "success" && state.email) {
      toast('Successfully unsubscribed from mailing list.');
    } else if (!state.email) {
      toast('Enter your email.');
    } else {
      toast('Failed to unsubscribe. Try again later.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer autoClose={2000} toastStyle={{ backgroundColor: "#2d2d2d", color: "white" }} />
      <AppBar
        position="absolute"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
          backgroundColor: "#262626"
        }}
      >
        <Typography variant="h4"
          sx={{
            color: "#dddddd",
            fontWeight: 'bolder',
            fontFamily: "Arial",
            paddingLeft: 3,
            paddingTop: 3,
          }}
          noWrap>
          HACKERX
        </Typography>
        <Typography variant="p"
          sx={{
            color: "#dddddd",
            fontFamily: "Arial",
            paddingLeft: 3,
            paddingBottom: 4,
          }}
          display="block"
          noWrap>
          Learn 🕵️‍♂️ Ethical Hacking in depth like never before
        </Typography>
      </AppBar>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3, backgroundColor: "#2d2d2d" } }}
        >
          <React.Fragment>
            {
              <React.Fragment>
                {getStepContent(activeStep, email)}
                {email ? <></> : <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {"Unsubscribe"}
                  </Button>
                </Box>}
              </React.Fragment>
            }
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
